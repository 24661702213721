/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, LazyExoticComponent } from 'react';

/**
 * Should be used instead of "React.lazy(...)" because it
 * already try's to reload the whole page if a chunk load error occurs.
 *
 * In case the chunk couldn't be loaded after refresh, the page will be automatically redirected to "/page-not-found".
 *
 * ChunkLoadErrors can appear if the app was published while the user has loaded
 * an old version of the app.
 *
 * @param {() => Promise<{
 *     default: ComponentType<any>;
 *   }>} factory E.g. () => import('./ComponentName')
 * @param {string} componentName Should be the unique name of the component to lazy load.
 * @return {*}  {LazyExoticComponent<ComponentType<any>>}
 */
export const lazyLoad = (
  factory: () => Promise<{
    default: ComponentType<any>;
  }>,
  componentName: string,
): LazyExoticComponent<ComponentType<any>> => {
  return React.lazy(() => retry(factory, componentName));
};

const retry = (
  factory: () => Promise<{
    default: ComponentType<any>;
  }>,
  componentName: string,
) => {
  return new Promise<{
    default: ComponentType<any>;
  }>((resolve, _) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-lazy-${componentName}-refreshed`) ||
        'false',
    );

    factory()
      .then(component => {
        window.sessionStorage.removeItem(
          `retry-lazy-${componentName}-refreshed`,
        );

        resolve(component);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error({
          errorType: 'lazyLoadError',
          componentName,
          error,
        });
        if (!hasRefreshed) {
          window.sessionStorage.setItem(
            `retry-lazy-${componentName}-refreshed`,
            'true',
          );

          return window.location.reload();
        }

        return window.location.assign(
          `${window.location.protocol}//${window.location.host}/page-not-found`,
        );
      });
  });
};
